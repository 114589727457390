import { Button, Input, message, Popconfirm, Row, Table } from "antd";
import "./TransformGif.scss";
import Column from "antd/lib/table/Column";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  GiftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import Axios from "axios";

const TransformGif = ({ totalPoint, phoneNumberUser, placeId }) => {
  const [gif, lstGif] = useState([]);
  const [update, setUpdate] = useState(0);
  const [display, setDisplay] = useState("");
  const [total, setTotal] = useState(0);
  const [pointRest, setPointRest] = useState(0);
  const [displayLstItems, setDisplayLstItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [inputNumber, setInputNumber] = useState(0);
  const data = [
    {
      key: 1,
      name: "SIX.am",
      point: 280,
      quantity: Math.floor(totalPoint / 280),
    },
    {
      key: 2,
      name: "Obaby",
      point: 220,
      quantity: Math.floor(totalPoint / 220),
    },
    {
      key: 3,
      name: "Combo xịt OSpray",
      point: 222,
      quantity: Math.floor(totalPoint / 222),
    },
  ];
  const chooseGif = (value) => {
    const cart = Object.assign(value, { quantityChoose: 1 });
    if (gif.length === 0) {
      gif.push(cart);
      setUpdate(update + 1);
    } else {
      const check = gif.findIndex((x) => {
        return x.key === value.key;
      });
      if (check !== -1) {
        gif[check] = {
          ...cart,
          quantityChoose: gif[check].quantityChoose + 1,
        };
        setUpdate(update + 1);
      } else {
        gif.push(cart);
        setUpdate(update + 1);
      }
    }
  };
  const delChoose = (record, index) => {
    const check = gif.findIndex((x) => {
      return x.key === record.key;
    });
    if (check !== -1) {
      gif.splice(check, 1);
      setUpdate(update + 1);
    } else {
      message.info("Không có sản phẩm trong danh sách đã chọn");
    }
  };
  const linkTransformGift = () => {
    setLoading(true);
    if (total > totalPoint) {
      setLoading(false);
      message.info("Số điểm của bạn không đủ để đổi quà");
    } else {
      const content = [];
      for (var value of gif) {
        content.push(value.name + ":" + value.quantityChoose);
      }
      const messageContent = content.join("; ");
      const req = {
        License: "TX9XD-98N7V-6WMQ6-BX7FG-H8Q99",
        PlaceID: placeId,
        Message: messageContent,
      };
      Axios.post(
        "https://icpc1hn.work/DTP/CustomerGift/ContactPayReward",
        req
      ).then((res) => {
        if (res.data.RespCode === 0) {
          setLoading(false);
          Swal.fire({
            title: "Thành công",
            text: "Công ty đã nhận được thông tin. Nhân viên CSKH sẽ liên lạc lại ngay với Nhà thuốc để tiến hành đổi thưởng. Xin cám ơn!",
            icon: "success",
            confirmButtonText: "Đóng",
          });
        } else {
          message.error(res.data.Resp);
        }
      });
    }
  };
  const checkPhoneNumber = () => {
    setLoadingBtn(true);
    if (inputNumber === phoneNumberUser) {
      setLoadingBtn(false);
      setDisplayLstItems(true);
    } else {
      setLoadingBtn(false);
      message.info("Số điện thoại không đúng");
    }
  };
  useEffect(() => {
    const total = gif.reduce((a, b) => {
      return a + b.quantityChoose * b.point;
    }, 0);
    setTotal(total);
    const rest = 1500 - total;
    setPointRest(rest);
  }, [update]);
  return (
    <div style={{ fontSize: 15, backgroundColor: "white", padding: 20 }}>
      <h2 className="title" style={{ textAlign: "center" }}>
        đổi thưởng tích lũy tại đây
      </h2>
      <div>
        <h2 className="inputPhoneNumber" id="scrollGift">
          Nhập số điện thoại để đổi thưởng
        </h2>
        <div className="input-btn">
          <Input onChange={(x) => setInputNumber(x.target.value)}></Input>
          <Button
            loading={loadingBtn}
            onClick={checkPhoneNumber}
            className="btn"
          >
            Đổi thưởng
          </Button>
        </div>
      </div>
      {displayLstItems && (
        <div>
          <div className="text">
            Điểm tích lũy của nhà thuốc hiện tại:{" "}
            <span className="title">{totalPoint}</span>
          </div>
          <div>
            <p className="text">Chọn sản phẩm đổi thưởng:</p>
            <div>
              <Table dataSource={data} style={{ width: "100%" }} width={300}>
                <Column title="STT" dataIndex="key" key="key" />
                <Column title="Tên sản phẩm" dataIndex="name" key="name" />
                <Column title="Điểm" dataIndex="point" key="point" />
                <Column title="Số hộp" dataIndex="quantity" key="quantity" />
                <Column
                  render={(text, record, index) => (
                    <div>
                      <Button
                        shape="round"
                        onClick={() => chooseGif(record)}
                        style={{ display: display, marginBottom: 5 }}
                      >
                        chọn
                      </Button>
                      <span
                        shape="round"
                        onClick={() => delChoose(record, index)}
                        style={{ display: display }}
                      >
                        <DeleteOutlined
                          style={{
                            fontSize: 22,
                            color: "#e83a3a",
                            fontWeight: "bold",
                          }}
                        />
                      </span>
                    </div>
                  )}
                />
              </Table>
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  Sản phẩm đã chọn:
                </div>
                <table style={{ width: "100%" }}>
                  <tr style={{ border: "1px solid gray" }}>
                    <th style={{ borderRight: "1px solid gray" }}>Tên</th>
                    <th style={{ borderRight: "1px solid gray" }}>SL</th>
                    <th>tổng</th>
                  </tr>
                  {gif &&
                    gif.map((x) => (
                      <tr
                        style={{ border: "1px solid gray", borderTop: "none" }}
                      >
                        <td style={{ borderRight: "1px solid gray" }}>
                          {x.name}
                        </td>
                        <td style={{ borderRight: "1px solid gray" }}>
                          {x.quantityChoose}
                        </td>
                        <td>{x.quantityChoose * x.point}</td>
                      </tr>
                    ))}
                </table>
                <div
                  className="title"
                  style={{
                    paddingBottom: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: 20,
                  }}
                >
                  <span>
                    Tổng điểm: <span style={{ fontSize: 30 }}>{total}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="tranfrom">
            <button onClick={linkTransformGift}>
              <span id="transformGift">
                <GiftOutlined style={{ paddingRight: 5, fontSize: 30 }} />
                liên hệ đổi thưởng
                {loading && <LoadingOutlined style={{ paddingLeft: 10 }} />}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransformGif;
