import React, { useEffect, useRef, useState } from "react";
import QrReader from "react-qr-reader";
import "./ScanQrImg.scss";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import { message, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
const ReaderQRCodeImg = ({ displayResult, result, infoPlaceCheck, historyPoint }) => {
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const handleScan = (data) => {
    if (data) {
      const apiHistoryPoit = 'https://icpc1hn.work/DTP/CustomerGift/GetHistoryPointByPID'
      Axios.post("https://icpc1hn.work/DTP/CustomerGift/GetPlacePointByID", {
        License: "TX9XD-98N7V-6WMQ6-BX7FG-H8Q99",
        QRCode: data,
      })
        .then((res) => {
          setLoading(false);
          if (res.data.RespCode === 0) {
            message.success("Đã lấy thông tin thành công");
            displayResult(true);
            infoPlaceCheck(res.data.PlaceInfo);
            Axios.post(apiHistoryPoit, {
              License: "TX9XD-98N7V-6WMQ6-BX7FG-H8Q99",
              PlaceID: res.data.PlaceInfo.PlaceID,
            }).then(res => {
              if(res.data.RespCode === 0) {
                historyPoint(res.data.HistoryPointLst.map((point, index) => {
                  return {
                    ...point,
                    Point: point.Point > 0 ? '+' + point.Point : '-' + Math.abs(point.Point),
                    index: index
                  }
                }))
              }
            })
          } else {
            setLoading(false);
            message.error(res.data.RespText);
          }
        })
        .catch((err) => {
          message.info(err);
        });
    }
    else {
      setLoading(false);
      message.error('Hãy chụp lại mã QR')
    }
  };
  const handleError = (err) => {
    alert(err);
  };
  const upLoadImg = () => {
    setLoading(true);
    ref.current.openImageDialog();
  };
 
  return (
    <div className="img_qrcode">
     
      <button className="btn_img_qrcode" onClick={upLoadImg}>
        <span>
          Quét mã QR tại đây
          {!loading && <ArrowRightOutlined style={{ marginLeft: 10 }} />}
          {loading && <LoadingOutlined style={{ marginLeft: 10 }} />}
        </span>
      </button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 150,
          display: "none",
        }}
        className="scan"
      >
        <QrReader
          ref={ref}
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%", height: "50%", display: "flex" }}
          className="qr_reader"
          legacyMode
        />
      </div>
    </div>
  );
};

export default ReaderQRCodeImg;
