import React, { useEffect, useState } from 'react'
import moment from 'moment'
import './HistoryPoint.scss'
import { Pagination } from 'antd'

const HistoryPoint = (info) => {
  let response = info.info.sort((a, b) => {
    return b.index - a.index
  })
  const [data, setData] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 5
  // useEffect(() =>{
  //   const fetchData = async () => {
  //     await Axios.post('https://icpc1hn.work/DTP/CustomerGift/GetHistoryPointByPID', {
  //       License: "TX9XD-98N7V-6WMQ6-BX7FG-H8Q99",
  //       PlaceID: '7860',
  //     }).then(res => {
  //       setData(res.data.HistoryPointLst.map(point => {
  //         return {
  //           ...point,
  //           Point: point.Point > 0 ? '+' + point.Point : '-' + Math.abs(point.Point)
  //         }
  //       }))
  //     })
  //   }
  //   fetchData()
  // }, [])
  const handleChange = (page, pageSize) => {
    setCurrentPage(page)
  }
  useEffect(() => {
    let begin = (currentPage - 1) * pageSize
    let end = (currentPage - 1) * pageSize + pageSize
    setDataTable([...response].slice(begin, end));
  }, [response, currentPage])
  return (
    <div className="history_point">
      <p className="title">Lịch sử quy đổi điểm</p>
      <div className="table_point">
        <table>
          <thead>
            <tr>
              <th>Điểm</th>
              <th>Ngày</th>
              <th>Nội dung</th>
            </tr>
          </thead>
          <tbody>
            {dataTable && dataTable.map((point, index) => (
              <tr key={index}>
                <td>{point.Point}</td>
                <td>{moment(point.TimePoint).format('DD/MM/YYYY')}</td>
                <td>{point.Content}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <div>
            <Pagination size="small" defaultCurrent={currentPage} pageSize={pageSize} onChange={handleChange} total={response.length}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HistoryPoint
