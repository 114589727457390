import Cookies from 'js-cookie'

function setToken(value){
    return Cookies.set('Token', value, {expires: 365})
}
function getToken(){
    return Cookies.get('Token')
}
function removeToken(){
    return Cookies.remove('Token')
}
function setUserName(value){
    return Cookies.set('UserName', value, {expires: 365})
}
function getUserName(){
    return Cookies.get('UserName')
}
function removeUserName(){
    return Cookies.remove('UserName')
}

export {setToken, getToken, removeToken, setUserName, getUserName, removeUserName}