import request from '../utils/request'
export function UserLogin(data) {
    return request({
      url: "/User/UserLogin",
      method: "post",
      data
    });
}

export function UserRegister(data) {
    return request({
      url: "/User/UserRegister",
      method: "post",
      data
    });
}