import React, { useEffect, useState } from 'react'
import { GetLocation } from '../../api/defaultValue'
const ResultByLocation = () => {
    const [ locations, setLocation ] = useState([])
    const getLocation = () => {
        GetLocation({
            CityCode: '',
            DistrictCode: ''
        }).then(res => {
            setLocation(res.LocationLst)
        })
    }
    useEffect(() => {
        getLocation()
    }, [])
    console.log(locations);
    return (
        <div>
            ResultByLocation
        </div>
    )
}

export default ResultByLocation
