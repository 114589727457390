import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Result from "./components/Result";
import TransformGif from "./components/Result/components/transformGif";
import Routes from "./routes";

function App() {
  return (
    <div className="App">
      <Routes></Routes>
      {/* <Router>
        <Switch>
          <Route path="/" exact component={Result} />
          <Route path="/doi-qua" exact component={TransformGif}/>
        </Switch>
      </Router> */}
    </div>
  );
}

export default App;
