import axios from 'axios'
import { getToken, getUserName } from './auth'
import { baseURL } from './baseURL'
import { message } from 'antd'
const service = axios.create({
    baseURL: baseURL, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000, // request timeout
    headers:{
        Accept: "application/json",
        "Content-Type": "application/json"
    }
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.data == null) {
        config.data = {};
    }
    if (getToken()) {
        config.data.Token = getToken();
        config.data.UserName = getUserName();
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if (response.data == null) {
        response.data = {};
      }
      const res = response.data;
      // if the custom code is not 20000, it is judged as an error.
      if (res.RespCode != 0) {
        message.error({
          content: res.RespText || "Error"
        });
        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        // if (res.RespCode == -1) {
        //   // to re-login
        //   MessageBox.confirm("Bạn đã bị đăng xuất, Đăng nhập lại?", "Thông báo", {
        //     confirmButtonText: "Đăng nhập",
        //     cancelButtonText: "Hủy",
        //     type: "warning"
        //   }).then(() => {
        //     store.dispatch("user/resetToken").then(() => {
        //       location.reload();
        //     });
        //   });
        // }
        // return Promise.reject(new Error(res.message || "Error"));
        return res
      }
      if (res.RespCode == 503) {
        message.warning({
          content: res.RespText || "Error"
        });
      } else {
        return res;
      }
  },
  error => {
    console.log('err' + error) // for debug
    message.error({
      content: error.message
    })
    return Promise.reject(error)
  }
)

export default service