import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Login } from "../components/Auth/components";
import Result from "../components/Result";
import ResultByLocation from "../components/ResultByLocation";
import PrivateRoute from "./privateRoute";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Result}/>
        <Route exact path='/login' component={Login}/>
        <Route exact path='/ket-qua-nha-thuoc' component={ResultByLocation}/>
        {/* <Route path="*" component={NotFound} /> */}

      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
