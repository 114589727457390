import { Button, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { UserOutlined, UnlockOutlined } from '@ant-design/icons'
import { UserLogin } from '../../../../api/user'
import "./Login.scss"
import { setToken, setUserName } from '../../../../utils/auth'

const Login = () => {
    const [ loading, setLoading ] = useState(false)
    const history = useHistory()
    const submit = (elements) => {
        UserLogin({
            UserName: elements.UserName.trim(),
            Password: elements.Password.trim()
        }).then(res => {
            if(res.RespCode === 0) {
                setToken(res.Token)
                setUserName(res.UserInfo.UserName)
                history.push('/ket-qua-nha-thuoc')
            }
        })
    }
    const backToHome = () => {
        history.push('/')
    }
    return (
        <div className="login">
            <div className="header">
                <p className="home" onClick={backToHome}>Trang chủ</p>
            </div>
            <div className="form">
                <h2>Đăng nhập</h2>
                <Form onFinish={submit} name="form">
                    <Form.Item name="UserName" rules={[{
                        required: true, message: 'Không được để trống'
                    }]}>
                        <Input placeholder="Số điện thoại" size='large' prefix={<UserOutlined />} className="text_input"/>
                    </Form.Item>
                    <Form.Item name="Password" rules={[{
                        required: true, message: 'Không được để trống'
                    }]}>
                        <Input.Password placeholder="Mật khẩu" size='large' prefix={<UnlockOutlined />} className="text_input"/>
                    </Form.Item>
                    <Form.Item className="action">
                        <Button htmlType="submit" type='primary' loading={loading} size="large" className="btnLogin">
                                Đăng nhập
                            </Button> 
                    </Form.Item>
                </Form>
           </div>
        </div>
    )
}

export default Login
