import React, { useRef, useEffect, useState } from "react";
import "./Result.css";
import "./Result.scss";
import banner from "../../assets/images/banner-nt.jpg";
import bxh from "../../assets/images/bxh.png";
import ntQA from "../../assets/images/nt-quoc-anh.JPG";
import six from "../../assets/images/hop-sixam.png";
import logoSix from "../../assets/images/logo-sixam.png";
import { message, Form, Modal, Input, Button } from "antd";
import ReaderQRCodeImg from "../ScanQrImg";
import Axios from "axios";
import "sweetalert2/src/sweetalert2.scss";
import { LoadingOutlined, GiftOutlined } from "@ant-design/icons";
import Select from "react-select";
import LstItem from "./components/LstItem";
import obaby from "../../assets/images/obaby.png";
import obaby123 from "../../assets/images/obaby123.png";
import logoObaby from "../../assets/images/logo-obaby.png";
import logoObaby123 from "../../assets/images/logo-obaby123.png";
import TransformGif from "./components/transformGif";
import HistoryPoint from './components/HistoryPoint'
// const { Option } = Select;
// const scrollToRef = (ref) =>
//   window.scrollTo({ top: 600 }, ref.current.offsetTop);
// const useMountEffect = (fun) => useEffect(fun, []);
const Result = () => {
  const [show, setShow] = useState(false);
  const [placeTopLst, setPlaceTopLst] = useState([]);
  const [placeInfo, setPlaceInfo] = useState({});
  const [historyPoint, setHistoryPoint] = useState([])
  const [lstCity, setLstCity] = useState([]);
  const [lstTop3, setLstTop3] = useState([]);
  const [chooseCity, setChooseCity] = useState([]);
  const [showBtnContact, setShowBtnContact] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [visible, setVisible] = useState(false);
  const [lstItems, setLstItems] = useState([]);
  const [totalPointGif, setTotalPointGif] = useState(0);
  const [form] = Form.useForm();
  // const myRef = useRef(null);

  // useMountEffect(() => scrollToRef(myRef)); // Scroll on mount
  // const clickScroll = (myRef) => {
  //   scrollToRef(myRef);
  // };

  const handleChange = (data) => {};
  const cbDisplayResult = (show) => {
    setShow(show);
    setShowBtnContact(show);
  };
  const cbResult = (data) => {
    setPlaceTopLst(data);
  };
  const cbPlaceInfo = (data) => {
    setPlaceInfo(data);
  };
  const cbHistoryPoint = (data) => {
    setHistoryPoint(data)
  }
  const cbLstItems = (data) => {
    setLstItems(data);
  };
  const cbTotalPoint = (data) => {
    setTotalPointGif(data);
  };
  const handleSelect = (data) => {
    setSelected(data.value);
    const selectCity =
      lstCity && lstCity.find((city) => city.City === data.value);
    setChooseCity(selectCity);
  };
  const scrollToGif  = () =>{
    const elmnt = document.getElementById('scrollGift')
    elmnt.scrollIntoView();
  }
  useEffect(() => {
    const getLstCity = async () => {
      await Axios.post(
        "https://icpc1hn.work/DTP/CustomerGift/GetTopPlacePoint",
        { License: "TX9XD-98N7V-6WMQ6-BX7FG-H8Q99" }
      )
        .then((res) => {
          if (res.data.RespCode === 0) {
            const response = res.data.CityTopPointLst.map((city) => {
              return {
                ...city,
                label: city.City,
                value: city.City,
              };
            });
            setLstTop3(res.data.PlaceTopLst);
            setLstCity(response);
          } else {
            message.error(res.data.RespText);
          }
        })
        .catch((err) => {
          message.info(err);
        });
    };
    getLstCity();
  }, []);
  const onFinish = (value) => {
    
    if (parseInt(value.phoneNumber) === parseInt(placeInfo.PhoneNumber)) {
     
    } else {
      message.error("Số điện thoại không đúng. Vui lòng nhập lại");
    }
  };
  return (
    <div style={{ backgroundColor: "#041e33" }}>
      <Modal
        title="Hãy nhập số điện thoại"
        visible={visible}
        confirmLoading={loading}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => setVisible(false)}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Hãy nhập số điện thọai",
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Form>
      </Modal>
      <div>
        <section className="nt-banner">
          <a
          // onClick={() => scrollToRef(myRef)}
          >
            <img src={banner} />
          </a>
        </section>
        <section
          className="nt-bxh"
          style={{ marginLeft: "3%", marginRight: "3%" }}
        >
          <div className="container">
            <div className="row">
              <div
                id="scan-qr"
                className="single-client col-xs-12"
                style={{ marginTop: 0 }}
              >
                <div className="single-info" style={{ paddingBottom: 0 }}>
                  <div id="btn-scan-qr">
                    <ReaderQRCodeImg
                      displayResult={cbDisplayResult}
                      result={cbResult}
                      infoPlaceCheck={cbPlaceInfo}
                      historyPoint={cbHistoryPoint}
                    />
                  </div>
                  {show && (
                    <div style={{ paddingBottom: 30 }}>
                      <div id="title-result">Kết quả tích điểm</div>
                      <div style={{ backgroundColor: "white" }}>
                        <div>
                          <img
                            id="img-result"
                            src={`${placeInfo.Image}`}
                            alt="Ảnh nhà thuốc"
                          />
                        </div>
                        <p className="single-title">{placeInfo.PlaceName}</p>
                        <p className="single-add">{`${placeInfo.District} ${placeInfo.City}`}</p>
                        <div style={{ display: "flex" }}>
                          <div
                            id="single-point"
                            className="single-point"
                            style={{ borderRight: "solid 1px rgba(0,0,0,0.1)" }}
                          >
                            <p>Điểm tích lũy</p>
                            <p className="point">{placeInfo.Point}</p>
                          </div>
                          <div className="single-point">
                            <p>Điểm khả dụng</p>
                            <p className="point">{placeInfo.PointReady}</p>
                          </div>
                        </div>
                      </div>
                      <div style={{backgroundColor: "white"}}>
                      <button id="btn-connect-gif" onClick={scrollToGif}> <GiftOutlined style={{fontSize: 30, paddingRight: 10}}/> Đổi thưởng</button>
                      <div className="historyPoint">
                        {
                          historyPoint.length > 0 ? <HistoryPoint info={historyPoint}></HistoryPoint> : 
                          <div className="notHistory">
                            Hiện tại chưa có lịch sử đổi điểm
                          </div>
                        }
                      </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="bxh-wrap col-xs-12" style={{ display: show }}>
                <div className="bxh-lead">
                  <img src={bxh} alt="" />
                </div>
                <div className="bxh-title">
                  <span>Theo tỉnh</span>
                </div>
                <div id="select">
                  <Select
                    placeholder="Vui lòng chọn tỉnh\thành phố"
                    onChange={handleSelect}
                    options={lstCity}
                  />
                  {/* <Select
                    showSearch
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    defaultValue="Vui lòng chọn tỉnh/thành phố"
                    onChange={handleSelect}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {lstCity.map((value) => (
                      <Option value={value.value}>{value.label}</Option>
                    ))}
                  </Select> */}
                </div>
                {chooseCity.City ? (
                  chooseCity.PlaceInfo.Latitude === 0 ? (
                    <div
                      style={{
                        color: "white",
                        textAlign: "center",
                        marginTop: 50,
                        fontSize: 25,
                        fontFamily: "Roboto Bold",
                        marginBottom: -30,
                      }}
                    >
                      Hiện đang cập nhật thông tin...
                    </div>
                  ) : (
                    <div
                      className="client"
                      style={{
                        display: "flex",
                        backgroundColor: "#ffe641",
                        marginTop: 40,
                      }}
                    >
                      <div
                        className="col-xs-1 client-no"
                        style={{ paddingLeft: 50, paddingRight: 50 }}
                      >
                        <span>1</span>
                      </div>
                      <div className="col-xs-7 client-info">
                        <p className="client-title">{`${chooseCity.PlaceInfo.PlaceName}`}</p>
                        <p className="client-add">
                          {`${chooseCity.PlaceInfo.District}, ${chooseCity.PlaceInfo.City}`}
                        </p>
                      </div>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginTop: 50,
                      fontSize: 25,
                      fontFamily: "Roboto Bold",
                      marginBottom: -30,
                    }}
                  >
                    Chọn Tỉnh/Thành phố bạn muốn tra cứu
                  </div>
                )}
              </div>
              <div className="bxh-wrap col-xs-12">
                <div className="bxh-lead" id="img-tinh">
                  <img src={bxh} alt="" />
                </div>
                <div className="bxh-title" id="title-tinh">
                  <span>Theo năm</span>
                </div>
                {/* <div id="select-year">
                  <Select
                    style={{ width: "100%" }}
                    defaultValue="Hà nội"
                    onChange={handleSelect}
                  >
                    {lstSelect.map((value) => (
                      <Option value={value.value}>{value.label}</Option>
                    ))}
                  </Select>
                </div> */}
                {/* <div className="top-client">
                  <div
                    className="color"
                    style={{
                      backgroundImage:
                        'url("../../assets/images/nt-quoc-anh.JPG")',
                      backgroundSize: "cover",
                    }}
                  >
                    <img src={no1} style={{ marginTop: 100 }} />
                    <p className="top-title">Nhà thuốc Quốc Anh</p>
                    <p className="top-add">Quận Thanh Xuân, Thành phố Hà Nội</p>
                  </div>
                </div> */}
                {lstTop3 &&
                  lstTop3.map((top, index) => (
                    <div>
                      {index === 0 ? (
                        <div
                          className="client"
                          style={{
                            display: "flex",
                            backgroundColor: "#ffe641",
                          }}
                        >
                          <div
                            className="col-xs-1 client-no"
                            style={{ paddingLeft: 50, paddingRight: 50 }}
                          >
                            <span>1</span>
                          </div>
                          <div className="col-xs-7 client-info">
                            <p className="client-title">{top.PlaceName}</p>
                            <p className="client-add">
                              {`${top.District}, ${top.City}`}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="client"
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            className="col-xs-1 client-no"
                            style={{ paddingLeft: 50, paddingRight: 50 }}
                          >
                            <span>{index + 1}</span>
                          </div>
                          <div className="col-xs-7 client-info">
                            <p className="client-title">{top.PlaceName}</p>
                            <p className="client-add">
                              {`${top.District}, ${top.City}`}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div id="scan-qr" className="single-client col-xs-12">
                <div className="single-info">
                  <div style={{ backgroundColor: "white", display: "none" }}>
                    <img src={ntQA} alt="" />
                    <p className="single-title">Nhà thuốc Quốc Anh</p>
                    <p className="single-add">
                      Quận Thanh Xuân, Thành phố Hà Nội
                    </p>
                    <div style={{ display: "flex" }}>
                      <div
                        className="single-point"
                        style={{ borderRight: "solid 1px rgba(0,0,0,0.1)" }}
                      >
                        <p>Điểm tích lũy</p>
                        <p className="point">450</p>
                      </div>
                      <div className="single-point">
                        <p>Điểm khả dụng</p>
                        <p className="point">170</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="list col-xs-12">
                <p className="list-lead" style={{ paddingTop: 40 }}>
                  Đổi điểm tích lũy ngay
                </p>
                <div
                  className="prod"
                  style={{
                    backgroundColor: "#041e33",
                    borderBottom: "1px solid #ffe641",
                  }}
                >
                  <div className="prod-img">
                    <img src={six} alt="" />
                  </div>
                  <div className="prod-info">
                    <img src={logoSix} alt="" />
                    <p className="prod-sub">Sản phẩm trị mất ngủ</p>
                    <p className="des">
                      Rotundin Sulfat, Saffron (Nghệ tây), Cao khô vông nem, Cao
                      khô tâm sen
                    </p>
                    <p className="prod-point">280 điểm</p>
                  </div>
                </div>
                <div
                  className="prod"
                  style={{
                    backgroundColor: "#041e33",
                    borderBottom: "1px solid #ffe641",
                  }}
                >
                  <div className="prod-img">
                    <img src={obaby} alt="" />
                  </div>
                  <div className="prod-info">
                    <img src={logoObaby} alt="" />
                    <p className="prod-sub">Bé ăn ngon - Tăng sức đề kháng</p>
                    <p className="des">
                      Bổ sung một số acid amin và kẽm. Giúp trẻ ăn ngon, giảm
                      mệt mỏi, tăng cường sức đề kháng. Giảm nguy cơ mắc các
                      bệnh đường hô hấp.
                    </p>
                    <p className="prod-point">220 điểm</p>
                  </div>
                </div>
                {/* <div
                  className="prod"
                  style={{
                    backgroundColor: "#041e33",
                    borderBottom: "1px solid #ffe641",
                  }}
                >
                  <div className="prod-img">
                    <img src={obaby123} />
                  </div>
                  <div className="prod-info">
                    <img style={{ maxWidth: 300 }} src={logoObaby123} />
                    <p className="prod-sub">
                      Bé ăn ngon - Tăng sức đề kháng
                      <br />
                      Dành cho trẻ từ 1-3 tuổi
                    </p>
                    <p className="des">
                      Bổ sung một số acid amin và kẽm. Giúp trẻ ăn ngon, giảm
                      mệt mỏi, tăng cường sức đề kháng. Giảm nguy cơ mắc các
                      bệnh đường hô hấp.
                    </p>
                    <p className="prod-point">95 điểm</p>
                  </div>
                </div> */}
              </div>
              <div>
              </div>
              <div>
                { placeInfo.PhoneNumber &&
                  <TransformGif totalPoint ={placeInfo.PointReady} phoneNumberUser={placeInfo.PhoneNumber} placeId = {placeInfo.PlaceID} id="scrollGift"/>
                }
              </div>
              <div className="tutorial col-xs-12">
                <p className="tut-lead" >Hướng dẫn</p>
                <div className="tut-text">
                  <p className="tut-title">1. Điểm tích lũy</p>
                  <p>
                    Điểm tích lũy thể hiện tất cả điểm thưởng mà Nhà thuốc đã
                    tích lũy được từ đầu chương trình. Mỗi khi khách hàng quét
                    mã QR của sản phẩm thành công, điểm thưởng sẽ được tự động
                    cộng vào Điểm tích lũy của Nhà thuốc. Mỗi sản phẩm sẽ có
                    điểm thưởng tương ứng.
                  </p>
                  <p className="tut-title">2. Điểm khả dụng</p>
                  <p>
                    Điểm khả dụng là số điểm thưởng còn lại để đổi thưởng của
                    Nhà thuốc. Khi đổi thưởng hoàn tất, điểm khả dụng sẽ bị trừ
                    đi dựa vào phần thưởng đã đổi.
                  </p>
                  <p className="tut-title">3. Liên hệ đổi thưởng</p>
                  <p>
                    Chọn sản phẩm để đổi thưởng bên trên, hoặc liên hệ qua
                    hotline: <a>0971.356.356</a>
                  </p>
                  <p className="tut-title">4. Bảng xếp hạng nhà thuốc</p>
                  <p>
                    Đều đặn hàng quý, năm, các Nhà thuốc đứng đầu các Bảng xếp
                    hạng tích lũy sẽ được trao tặng những phần quà vô cùng giá
                    trị. Nhà thuốc có <b>Điểm tích lũy</b> cao nhất của
                    tỉnh/thành phố của mình cũng sẽ được trao tặng những phần
                    quà hấp dẫn.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="footer">
          <div className="container">
            <div
              className="row"
              style={{ marginLeft: "4%", marginRight: "4%" }}
            >
              <div className="footer-wrap col-xs-12">
                <img src={logoSix} style={{ marginTop: 30 }} />
                <p className="slogan">Quà tặng cho giấc ngủ</p>
                <p>
                  Để đặt hàng và liên hệ đổi thưởng, vui lòng liên hệ theo số
                  Hotline{" "}
                  <a href="#" style={{ color: "#dabc3d" }}>
                    0971.356.356
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Result;
